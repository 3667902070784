/**
 * Returns a Date object based on the provided input.
 *
 * @param {(Date | number)} date - The input date to be converted. It can be a JavaScript Date object or a number representing a timestamp.
 * @return {Date} - A new Date object representing the input date.
 */
export function getDate (date: Date | number): Date {
    if (typeof date === "number") {
        // check if it's a javascript timestamp
        if (date.toString().length === 10) {
            date *= 1000;
        }

        date = new Date(date);
    }

    return date;
}

/**
 * Converts a given date or timestamp into a formatted string.
 * The formatted string includes the date in the format "dd/mm/yyyy"
 * and the time in the format "hh:mm:ss".
 * If a string is provided, it will be converted to a Date object.
 *
 * @param {Date | number | string} baseDate - The base date or timestamp to be formatted.
 * @param locale - The locale to use for formatting the date and time. Can be 'en', 'jp', or 'fr'.
 * @return {string} The formatted date and time string in the format "dd/mm/yyyy à hhh:mm:ss".
 */
export function useDate (baseDate: Date | number | string, locale: string = "fr"): string {
    if (typeof baseDate === "string") {
        baseDate = new Date(baseDate);
    }

    let localeString = "fr-FR";
    switch (locale) {
    case "en":
        localeString = "en-US";
        break;
    case "jp":
        localeString = "ja-JP";
        break;
    }

    baseDate = getDate(baseDate);
    const date = baseDate.toLocaleDateString(localeString, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
    });
    const hours = baseDate.getHours().toString().padStart(2, "0");
    const minutes = baseDate.getMinutes().toString().padStart(2, "0");
    const seconds = baseDate.getSeconds().toString().padStart(2, "0");

    switch (locale) {
    case "en":
        return `${date} at ${hours}:${minutes}:${seconds}`;
    case "jp":
        return `${date} ${hours}時${minutes}分${seconds}秒`;
    default:
        return `${date} à ${hours}h${minutes}m${seconds}s`;
    }
}

/**
 * Converts a given date to a short date format with the format: 'dd/mm/yyyy'
 * @param {Date | number | string | undefined} baseDate - The date to convert. It can be a Date object, a timestamp (number), a string representation of a date, or undefined.
 * @return {string} - The date in the short date format: 'dd/mm/yyyy'
 */
export function useShortDate (baseDate: Date | number | string | undefined): string {
    if (!baseDate) {
        return "";
    }
    if (typeof baseDate === "string") {
        baseDate = new Date(baseDate);
    }

    baseDate = getDate(baseDate);

    return baseDate.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    });
}

/**
 * Returns the month and year in the format "month year" for the given base date.
 *
 * @param {Date | number} baseDate - The base date. Can be a Date object or a number representing milliseconds since January 1, 1970, 00:00:00 UTC.
 * @returns {string} - The month and year in the format "month year".
 */
export function useMonthYear (baseDate: Date | number): string {
    if (!baseDate) {
        return "";
    }
    baseDate = getDate(baseDate);
    return baseDate.toLocaleDateString("fr-FR", {
        month: "long",
        year: "numeric"
    });
}

/**
 * Converts a Date object or a number representing a timestamp into a simple date string.
 * @param {Date | number} date - The Date object or timestamp to be converted.
 * @param t - The translation function.
 * @return {string} The simple date string in the format of "DD Month YYYY".
 */
export function useSimpleDate (date: Date | number, t: any): string {
    if (!date) {
        return "";
    }
    date = getDate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = t(useMonths()[date.getMonth()]);
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
}

/**
 * Generates and returns a random date between January 1, 2020 and December 24, 2023.
 *
 * @returns {Date} A random date.
 */
export function useRandomDate (): Date {
    const start = new Date(2020, 0, 1).getTime();
    const end = new Date(2023, 12, 24).getTime();
    const randomTimestamp = start + (Math.random() * (end - start));
    return new Date(randomTimestamp);
}

/**
 * Returns an array of months in French.
 *
 * @returns {string[]} An array of month names in French (janvier, février, mars, etc.).
 */
export function useMonths (): string[] {
    return [
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "november",
        "december"
    ]
        .map(month => `label.months.${month}`);
}

/**
 * Retrieves an array of clean months.
 *
 * @returns {string[]} An array of clean months in the format "MM - month".
 */
export function useCleanMonths (): string[] {
    const months = useMonths();
    return months.map((month, index) => `${(index + 1).toString().padStart(2, "0")} - ${month}`);
}

/**
 * Gets the list of months options for use in a select dropdown.
 *
 * @function
 * @returns {Array<{ label: string, value: string }>} - An array of objects representing the months options. Each object has a `label` property representing the formatted month name
 * and a `value` property representing the month number as a zero-padded string.
 */
export function useMonthsOptions (): { label: string; value: string; }[] {
    const months = useMonths();
    const formattedMonths = useCleanMonths();
    return months.map((_, index) => ({
        label: formattedMonths[index],
        value: (index + 1).toString().padStart(2, "0")
    }));
}

/**
 * Generates an array of years based on the current year.
 *
 * @param {number} numberOfYears - The number of years to generate.
 * @param {boolean} [future=false] - Whether to generate years in the future or in the past.
 *
 * @returns {number[]} An array of years.
 */
export function useYears (numberOfYears: number, future: boolean = false): number[] {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 0; i < numberOfYears; i++) {
        years.push(currentYear + (future ? i : -i));
    }
    return years;
}

/**
 * Formats the month and year into a string.
 *
 * @param {number} month - The month (1-12).
 * @param {number} year - The year.
 * @return {string} - The formatted month and year string.
 */
export function formatMonthYear (month: number, year: number): string {
    const monthName = useMonths()[month - 1];
    return `${monthName} ${year}`;
}

/**
 * Calculates the number of days between two dates.
 *
 * @param {Date | number} date1 - The first date.
 * @param {Date | number} date2 - The second date.
 * @returns {number} The number of days between the two dates.
 */
export function daysBetween (date1: Date | number, date2: Date | number): number {
    if (!date1 || !date2) {
        return 0;
    }

    const date1Time = getDate(date1).getTime();
    const date2Time = getDate(date2).getTime();
    const dayTime = 1000 * 60 * 60 * 24;

    return Math.round(Math.abs((date1Time - date2Time) / dayTime));
}

/**
 * Calculates the number of days between the current date and the given date.
 *
 * @param {Date | number | null} date - The date to calculate the number of days between the current date and.
 *
 * @return {number} - The number of days between the current date and the given date.
 *
 * @example
 *
 * // Calculate the number of days between today and a specific date
 * const result = daysBetweenNow(new Date('2022-01-01'));
 * console.log(result); // Output: 76
 *
 * // Calculate the number of days between today and null
 * const result = daysBetweenNow(null);
 * console.log(result); // Output: 0
 */
export function daysBetweenNow (date: Date | number | null): number {
    if (!date) {
        return 0;
    }
    return daysBetween(new Date(), date);
}

/**
 * Determines if the current date falls within the given start and end dates.
 *
 * @param {Date|string|undefined} startDate - The start date. If undefined, the start date is not considered.
 * @param {Date|string|undefined} endDate - The end date. If undefined, the end date is not considered.
 * @return {boolean} - Returns true if the current date falls within the start and end dates, otherwise returns false.
 */
export function dateApplicable (startDate: Date | string | undefined, endDate: Date | string | undefined): boolean {
    if (!startDate && !endDate) {
        return true;
    }

    if (startDate && typeof startDate === "string") startDate = new Date(startDate);
    if (endDate && typeof endDate === "string") endDate = new Date(endDate);

    const now = new Date();

    if (startDate && endDate) {
        return now >= startDate && now <= endDate;
    }

    if (startDate) {
        return now >= startDate;
    }

    if (endDate) {
        return now <= endDate;
    }

    return false;
}

export function getDateAgo (diff: number): Date {
    const date = new Date();
    date.setDate(date.getDate() - diff);
    return date;
}

export function getDate30DaysAgo (fromDate?: Date | undefined): Date {
    const date = fromDate || new Date();
    date.setDate(date.getDate() - 30);
    return date;
}

export function isDateInFuture (date: Date | number): boolean {
    if (!date) {
        return false;
    }

    // if date is timestamp, convert it to Date object
    if (typeof date === "number") {
        date = new Date(date);
    }

    return date > new Date();
}
