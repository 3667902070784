import type { ToRefs } from "vue";

import { routesNextcloud } from "~/routes/declarations";

import type { DriveProInfos, UsedQuota } from "~/types/drive";
import type { PBCollection } from "~/types/pb/collections";
import { Sub } from "~/types/subscriptions";

import Subscription, { type SubscriptionConfig } from "~/classes/Subscription";

/**
 * Represents information about a Drive Pro subscription.
 *
 * @interface ISubscriptionDrivePro
 * @property {boolean} isActive - Whether the subscription is active
 * @property {boolean} isDisabled - Whether the subscription is disabled
 * @property {boolean} isLoading - Whether the subscription is loading
 * @property {number} spaceUsed - The amount of space used
 * @property {number} spaceTotal - The total amount of space
 * @property {number} usersUsed - The amount of users
 * @property {number} usersTotal - The total amount of users
 * @property {boolean} isUnlimitedUsers - Whether the subscription has unlimited users
 * @property {boolean} isUnlimitedSpace - Whether the subscription has unlimited space
 * @property {string} url - The URL of the drive
 */
export interface ISubscriptionDrivePro {
    instanceID: string,
    reference: string
    spaceUsed: number,
    spaceTotal: number,
    usersUsed: number,
    usersTotal: number,
    isUnlimitedUsers: boolean,
    isUnlimitedSpace: boolean,
    url: string,
}

export type SubDrivePro = Subscription & ISubscriptionDrivePro & {
    setReference: (newReference: string) => void;
    getSpaceQuota: () => UsedQuota;
    getUsersQuota: () => UsedQuota;
};

/**
 * Represents a subscription drive pro.
 */
export default class SubscriptionDrivePro extends Subscription implements ToRefs<ISubscriptionDrivePro> {
    public instanceID = ref<string>("");

    public reference = ref<string>("");

    public spaceUsed = ref<number>(0);

    public spaceTotal = ref<number>(0);

    public usersUsed = ref<number>(0);

    public usersTotal = ref<number>(0);

    public isUnlimitedUsers = ref<boolean>(false);

    public isUnlimitedSpace = ref<boolean>(false);

    public url = ref<string>("");

    public pbItem = ref<PBCollection.AboDrivePro | null>(null);

    /**
     *  Creates a new SubscriptionDrivePro instance.
     *
     * @param {SubscriptionConfig} config - The configuration object for the subscription drive pro
     */
    public constructor (config: SubscriptionConfig) {
        super(config);

        this.fetchDriveProData()
            .then(() => __subscription().setLoading(Sub.Type.DrivePro, false))
            .catch((error: any) => {
                __subscription().setLoading(Sub.Type.DrivePro, false);
                console.error(error);
            });
    }

    /**
     * Sets the reference of the subscription.
     *
     * @param newReference - The new reference to set
     */
    public setReference (newReference: string): void {
        this.reference.value = newReference || this.reference.value || "general.dashboard.panel.drive_pro.title";
    }

    /**
     * Get the space quota.
     *
     * @returns The quota.
     */
    public getSpaceQuota (): UsedQuota {
        return {
            used: this.spaceUsed.value,
            quota: this.pbItem.value?.quota_to ?? -1,
            unit: "B"
        };
    }

    /**
     * Get the users quota.
     *
     * @returns The quota.
     */
    public getUsersQuota (): UsedQuota {
        return {
            used: this.usersUsed.value,
            quota: this.pbItem.value?.quota_user ?? -1,
            unit: "users"
        };
    }

    /**
     * Fetches DrivePro data from Nextcloud and PB.
     *
     * @returns {Promise<void>} A Promise that resolves once the DrivePro data has been fetched.
     */
    private async fetchDriveProData (): Promise<void> {
        const infosPromise
            = useFetchRoute<Record<string, DriveProInfos>>(routesNextcloud.driveProInfos) ?? [];
        const PBInfos = PB.i.collection<PBCollection.AboDrivePro>("abo_drive_pro").getFullList();
        const [ infos, PBInfosList ] = await Promise.all([ infosPromise, PBInfos ]);

        if (Object.keys(infos).length === 0) {
            console.warn("No drive pro infos found");
            return;
        }

        if (PBInfosList.length === 0) {
            console.warn("No drive pro infos found in PB");
            return;
        }

        const pbInfos = PBInfosList.find(info => info.subscription_id === this.subscriptionItem.id);
        if (!pbInfos || !infos[pbInfos.id]) {
            console.warn("No drive pro infos found in PB for this subscription");
            return;
        }

        this.setReference(pbInfos.reference);
        this.setUrl(pbInfos.url);

        const { nb_size, nb_users } = infos[pbInfos.id];

        this.setSpaceUsed(nb_size);
        this.setUsersUsed(nb_users);

        this.setUnlimited(pbInfos);

        this.pbItem.value = pbInfos;
        this.instanceID.value = pbInfos.id;
    }

    /**
     * Sets the used space.
     *
     * @param {number} spaceUsed - The used space in bytes
     */
    private setSpaceUsed (spaceUsed: number | string | undefined): void {
        if (!spaceUsed) {
            return;
        }

        this.spaceUsed.value = parseInt(`${spaceUsed}`);
    }

    /**
     * Sets the total space.
     *
     * @param {number} spaceTotal - The total space in bytes
     */
    private setSpaceTotal (spaceTotal: number | string | undefined): void {
        if (!spaceTotal) {
            return;
        }

        this.spaceTotal.value = parseInt(`${spaceTotal}`);
    }

    /**
     * Sets the used users.
     *
     * @param {number} usersUsed - The used users
     */
    private setUsersUsed (usersUsed: number | string | undefined): void {
        if (!usersUsed) {
            return;
        }

        this.usersUsed.value = parseInt(`${usersUsed}`);
    }

    /**
     * Set url.
     *
     * @param {string} url - The url
     */
    private setUrl (url: string): void {
        this.url.value = url || "#no-url";
    }

    /**
     * Set unlimited space and users.
     *
     * @param pbInfos - The PB infos
     */
    private setUnlimited (pbInfos: PBCollection.AboDrivePro): void {
        switch (pbInfos.subscription_type) {
        case "full_metered":
            this.isUnlimitedSpace.value = true;
            this.isUnlimitedUsers.value = true;
            break;
        case "to_metered":
            this.isUnlimitedSpace.value = true;
            this.isUnlimitedUsers.value = false;
            break;
        case "user_metered":
            this.isUnlimitedSpace.value = false;
            this.isUnlimitedUsers.value = true;
            break;
        default:
            this.isUnlimitedSpace.value = false;
            this.isUnlimitedUsers.value = false;
        }
    }
}
