import { __alert } from "~/stores/alert.store";
import { __identifier } from "~/stores/identifier.store";
import { __pbUser } from "~/stores/pb-user.store";

import type { Alert as AlertType } from "~/types/alert";
import { Sub } from "~/types/subscriptions";
import type { $t } from "~/types/t";

import type { SubObjectStorage } from "~/classes/subscriptions/SubscriptionObjectStorage";

const identifiersLoaded = ref<boolean>(false);
export const hasFetchSubscriptions = ref<boolean>(false);

export async function checkNotifications (t: any, config: any): Promise<void> {
    await __pbUser().init();

    __identifier().getIdentifiers()?.then(() => {
        identifiersLoaded.value = true;
    });
    watchS3Trial(t);
    watchDeactivated();
    watchProblem(t, config);
    checkPaymentStatus(t, config);
    await checkNoSubscription(false);
}

function watchS3Trial (t: any): void {
    const unwatch = watchEffect(() => {
        const objectStorage = __subscription().get<SubObjectStorage>(Sub.Type.ObjectStorage);
        if (
            !__user().user // If user is not initialized
            || !__subscription().has(Sub.Type.ObjectStorage) // If user has no object storage subscription
            || !identifiersLoaded.value // If identifiers are not loaded
            || !objectStorage?.isTrial // If object storage subscription is not a trial
        ) {
            setTimeout(() => {
                unwatch();
            }, 10000);
            return;
        }

        const trialInformations = objectStorage.getTrialInformations();
        if (objectStorage && objectStorage.isTrial && trialInformations) {
            const daysUntilEnd = daysBetweenNow(trialInformations.trialEnd);
            let message: string;
            let type = "info";

            if (objectStorage.isQuotaReached) {
                message = "modals.trial.content.quota_reached";
                type = "danger";
            } else {
                message = t("modals.trial.content.remaining_days", { days: daysUntilEnd, count: daysUntilEnd });
            }

            __alert().add({
                id: "s3-trial",
                type: type as AlertType["type"],
                title: "modals.trial.title",
                message,
                actions: [
                    {
                        label: "label.contact",
                        severity: "primary",
                        icon: "send-mail",
                        href: "https://www.leviia.com/contact-pro/"
                    }
                ],
                isDismissible: false
            });

            unwatch();
        }
    });
}

function watchDeactivated (): void {
    let unwatch: any = null;
    unwatch = watchEffect(() => {
        if (__pbUser().isDeactivated
            || (
                __subscription().has(Sub.Type.ObjectStorage)
                && __subscription().getIsReadonly(Sub.Type.ObjectStorage)
                && __pbUser().hasParent
            )
        ) {
            __alert().add({
                id: "reseller-deactivated",
                type: "danger",
                isDismissible: false,
                title: "modals.partners.disabled_by_distributor.title",
                message: "modals.partners.disabled_by_distributor.content"
            });

            if (unwatch) {
                unwatch();
            }
        }
    });

    setTimeout(() => {
        unwatch();
    }, 5000);
}

function watchProblem (
    t: any,
    config: any
): void {
    const supportEmail = config.SUPPORT_EMAIL;

    const unwatch = watchEffect(() => {
        if (__user().hasProblem && __pbUser().isInitialized) {
            __alert().add({
                id: "no-payment",
                type: "danger",
                title: "modals.subscription_problem.title",
                message: t("modals.subscription_problem.content", createEmailLink(supportEmail)),
                isDismissible: false
            });

            unwatch();
        }
    });

    setTimeout(() => {
        unwatch();
    }, 5000);
}

function showUnpaidNotification (
    hasMultipleReadonly: boolean,
    isReadOnlyObjectStorage: boolean,
    t: $t,
    isReadOnlyPartner: boolean,
    isReadOnlyDrive: boolean,
    isReadOnlyDrivePro: boolean,
    supportEmail: any
): void {
    let subscriptionName = "";

    if (!hasMultipleReadonly) {
        if (isReadOnlyObjectStorage) {
            subscriptionName = t("label.subscription.object-storage");
        } else if (isReadOnlyPartner) {
            subscriptionName = t("label.subscription.partner-long");
        } else if (isReadOnlyDrive) {
            subscriptionName = t("label.subscription.drive");
        } else if (isReadOnlyDrivePro) {
            subscriptionName = t("label.subscription.drive-pro");
        }
    }

    const title = hasMultipleReadonly
        ? t("notification.suspended_account.title.multiple")
        : t("notification.suspended_account.title.single", { sub: subscriptionName });

    __alert().add({
        id: "unpaid",
        type: "danger",
        title,
        message: t("notification.suspended_account.content"),
        isDismissible: false,
        actions: [
            {
                label: t("label.parameters"),
                route: "/user/subscriptions",
                severity: "secondary",
                icon: "settings"
            },
            {
                label: t("label.contact"),
                href: `mailto:${supportEmail}`,
                severity: "primary",
                icon: "send-mail"
            }
        ]
    });
}

function checkPaymentStatus (t: any, config: any): void {
    const supportEmail = config.SUPPORT_EMAIL;

    const isReadOnlyObjectStorage = __subscription().getIsReadonly(Sub.Type.ObjectStorage);
    const isReadOnlyPartner = __subscription().getIsReadonly(Sub.Type.Partner);
    const isReadOnlyDrive = __subscription().getIsReadonly(Sub.Type.Drive);
    const isReadOnlyDrivePro = __subscription().getIsReadonly(Sub.Type.DrivePro);

    const readOnlySubscriptions = [
        isReadOnlyObjectStorage,
        isReadOnlyPartner,
        isReadOnlyDrive,
        isReadOnlyDrivePro
    ];

    const hasReadOnly = readOnlySubscriptions.some(readOnly => readOnly);
    const hasMultipleReadonly = readOnlySubscriptions.filter(readOnly => readOnly).length > 1;
    let unwatch: any = null;
    unwatch = watchEffect(() => {
        if (!hasReadOnly || __pbUser().hasParent) {
            setTimeout(() => {
                unwatch();
            }, 5000);

            return;
        }

        showUnpaidNotification(
            hasMultipleReadonly,
            isReadOnlyObjectStorage,
            t,
            isReadOnlyPartner,
            isReadOnlyDrive,
            isReadOnlyDrivePro,
            supportEmail
        );

        if (unwatch) unwatch();
    });
}

export async function checkNoSubscription (forceNoWait = false): Promise<void> {
    // eslint-disable-next-line func-style
    let unwatch = (): void => {
    };

    if (!forceNoWait) {
        await waitForSubscription(Sub.Type.ObjectStorage);
    }

    function checkSub (): void {
        if (!hasFetchSubscriptions.value) {
            return;
        }

        if (__subscription().getSubscriptions.length === 0) {
            __alert().add({
                id: "no-payment",
                type: "info",
                title: "notification.no_sub.title",
                message: "notification.no_sub.content",
                isDismissible: false,
                actions: [
                    {
                        label: "notification.no_sub.cta.website",
                        href: "https://www.leviia.com",
                        icon: "language",
                        severity: "secondary"
                    },
                    {
                        label: "notification.no_sub.cta.parameters",
                        route: "/user/billing/details",
                        icon: "settings"
                    }
                ]
            });
        } else {
            __alert().remove("no-payment");
        }

        setTimeout(() => {
            unwatch();
        }, 30000);
    }

    if (forceNoWait) {
        checkSub();
    } else {
        unwatch = watch(() => __subscription().getSubscriptions, checkSub, {
            deep: true,
            immediate: true
        });
    }
}
