import { __alert } from "~/stores/alert.store";
import { __stripe } from "~/stores/stripe.store";

/**
 * Verifies the given customer ID.
 *
 * @param {string} customerId - The customer ID to be verified.
 *
 * @throws {Error} Throws an error if no customer ID is found.
 */
function verifyCustomerId (customerId: string): void {
    if (!customerId) {
        __alert().add({
            id: "no-payment",
            type: "danger",
            title: "Problème avec votre abonnement détecté",
            message: `Nous vous invitons à contacter notre support :
            <a href="mailto:support@leviia.com" target="_blank">support@leviia.com</a>`,
            isDismissible: false
        });

        __alert().remove("no-subscription-paid");

        throw new Error("No customer ID found");
    }
}

/**
 * Retrieves the customer ID.
 *
 * @param {string | undefined} customerId - The customer ID to retrieve. If not provided, it will be obtained from the Stripe store.
 *
 * @returns {Promise<string>} A Promise that resolves to the customer ID.
 *
 * @throws {Error} If no customer ID is found.
 */
export async function getCustomerID (customerId?: undefined | null | string): Promise<string> {
    // Wait for the KC
    await waitForKC();

    // If the customerId is not provided, wait for the PB
    if (!__user().getUser.customerId) {
        await waitForPB();
    }

    // If the customerId is still not provided, get it from the stripe store
    if (!customerId) {
        customerId = __stripe().getCustomerId || __stripe().getParentCustomerId;
    }

    verifyCustomerId(customerId);

    return customerId;
}
