import type { Sub } from "~/types/subscriptions";

export namespace StripeRaw {
    export type Metadata = {
        abonnement_kc?: string
        abonnement?: string
        subscription_type?: string
        leviia_id?: string
        quota?: string
        space_allowed?: string
        user_allowed?: string
    };

    export type MetadataKey = keyof StripeRaw.Metadata;

    export type SubscriptionDataItemsDataPriceRecurring = {
        aggregate_usage: string
        interval: string
        interval_count: number
        usage_type: string
    };

    export type Price = {
        id: string
        object: string
        active: boolean
        billing_scheme: string
        created: number
        currency: string
        livemode: boolean
        metadata: Metadata
        nickname: string
        product: string
        recurring: SubscriptionDataItemsDataPriceRecurring
        tax_behavior: string
        tiers_mode: string
        type: string
    };

    export type Plan = {
        id: string
        object: string
        active: boolean
        aggregate_usage: string
        billing_scheme: string
        created: number
        currency: string
        interval: string
        interval_count: number
        livemode: boolean
        metadata: Metadata
        nickname: string
        product: string
        tiers_mode: string
        usage_type: string
    };

    export type Subscription = {
        id: string
        object: string
        billing_cycle_anchor: number
        cancel_at_period_end: boolean
        collection_method: string
        created: number
        current_period_end: number
        current_period_start: number
        customer: string
        default_tax_rates: any[]
        trial_end?: number
        items: Subscriptions | null
        latest_invoice: string
        livemode: boolean
        metadata: Metadata
        plan: Plan | null
        quantity: number
        tax_percent: number
        start_date: number
        cancel_at?: number
        canceled_at?: number
        bandeau?: string
        name?: string
        icon?: string
    } & ({
        status: Sub.Status
    } | {
        status: Sub.Status.Trialing
        trial_start: number
        trial_end: number
        quota_trial_to?: number
    });

    export type Subscriptions = {
        object: string
        data: Subscription[]
        has_more: boolean
        total_count?: number
        url: string
    };

    export type AboDrivePro = {
        id: string
        collectionId: string
        collectionName: string
        created: string
        updated: string
        equipe: string
        reference: string
        name: string
        url: string
        hds: boolean
        quota_user: number
        product_metered_user: string
        quota_to: number
        product_metered_to: string
        leviia_deactivated: boolean
        instance: string
        subscription_id: string
        subscription_type: string
    };

    export type Product = {
        id: string;
        object: string;
        active: boolean;
        attributes: any[];
        caption: string;
        created: number;
        deactivate_on: any[];
        description?: any;
        images: any[];
        livemode: boolean;
        metadata: Metadata;
        name: string;
        package_dimensions?: any;
        shippable?: any;
        statement_descriptor?: any;
        unit_label?: any;
        updated: number;
        url?: any;
    };

    export type ObjectWithMetadata = StripeRaw.Subscription
        | StripeRaw.Product
        | StripeRaw.Plan
        | StripeRaw.Price;

    export type Item = StripeRaw.Subscription
        | StripeRaw.Product
        | StripeRaw.Plan;

    export enum ItemType {
        Subscription = "subscription",
        Product = "product",
        Plan = "plan",
        Price = "price"
    }
}
