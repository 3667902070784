import type { RuntimeConfig } from "nuxt/schema";

import { __product } from "~/stores/product.store";

import { getMetadata } from "~/utils/subscriptions";

import type { StripeRaw } from "~/types/stripe/raw";
import { Sub } from "~/types/subscriptions";

import type Subscription from "~/classes/Subscription";
import { SubscriptionConfig } from "~/classes/SubscriptionConfig";
import SubscriptionDrive from "~/classes/subscriptions/SubscriptionDrive";
import SubscriptionDrivePro from "~/classes/subscriptions/SubscriptionDrivePro";
import SubscriptionObjectStorage from "~/classes/subscriptions/SubscriptionObjectStorage";
import SubscriptionPartner from "~/classes/subscriptions/SubscriptionPartner";
import { SubscriptionValidator } from "~/classes/SubscriptionValidator";

/**
 * Represents the information related to a subscription.
 */
interface SubscriptionInformations {
    subscriptionType: Sub.Type;
    product: StripeRaw.Product;
    plan: StripeRaw.Plan;
}

/**
 * Factory class for creating subscriptions.
 */
export default class SubscriptionFactory {
    /**
     * Variable representing a dictionary object that maps subscription types to their associated classes.
     *
     * @type {Record<Sub.Type, new (...args: any[]) => Subscription>}
     */
    private static readonly subscriptionTypeAssociatedClasses: Record<Sub.Type, new (...args: any[]) => Subscription> = {
        [Sub.Type.ObjectStorage]: SubscriptionObjectStorage,
        [Sub.Type.Partner]: SubscriptionPartner,
        [Sub.Type.Drive]: SubscriptionDrive,
        [Sub.Type.DrivePro]: SubscriptionDrivePro
    };

    /**
     * Creates subscriptions based on the main subscription.
     *
     * @param {StripeRaw.Subscription} mainSubscription - The main subscription object with all the necessary details.
     * @param {RuntimeConfig["public"]} runtimeConfig - The runtime configuration object.
     * @returns {Promise<Subscription[]>} - A promise that resolves to an array of newly created subscriptions.
     */
    public static async create (mainSubscription: StripeRaw.Subscription, runtimeConfig: RuntimeConfig["public"]): Promise<Subscription[]> {
        if (!SubscriptionValidator.isValidSubscriptionStatus(mainSubscription.status)) {
            console.error("❌ Invalid subscription status.");
            return [];
        }

        if (this.subscriptionHasManyItems(mainSubscription)) {
            return this.createMultipleSubscriptions(mainSubscription, runtimeConfig);
        } else {
            const subscription = await this.createSubscription(mainSubscription, undefined, runtimeConfig);
            return subscription ? [ subscription ] : [];
        }
    }

    /**
     * Checks if a subscription has multiple items.
     *
     * @param {StripeRaw.Subscription} subscription - The subscription to check.
     * @returns {boolean} - `true` if the subscription has multiple items, `false` otherwise.
     */
    private static subscriptionHasManyItems (subscription: StripeRaw.Subscription): boolean {
        return subscription.items ? subscription.items.data.length > 1 : false;
    }

    /**
     * Creates a subscription.
     *
     * @param {StripeRaw.Subscription} subscription - The subscription information.
     * @param {StripeRaw.Subscription} parentSubscription - The parent subscription information.
     * @param {RuntimeConfig["public"]} runtimeConfig - The runtime configuration object.
     * @returns {Promise<Subscription | null>} - The created subscription object or null if unsuccessful
     */
    private static async createSubscription (
        subscription: StripeRaw.Subscription,
        parentSubscription?: StripeRaw.Subscription,
        runtimeConfig: RuntimeConfig["public"] | undefined = undefined
    ): Promise<Subscription | null> {
        if (!runtimeConfig) {
            console.error("❌ Runtime configuration not found.");
            return null;
        }

        const subscriptionInformations = await this.getSubscriptionInformations(subscription);
        if (!subscriptionInformations) {
            return null;
        }

        // eslint-disable-next-line prefer-const
        let { subscriptionType, product, plan } = subscriptionInformations;

        subscriptionType = this.checkUserParent(subscriptionType);

        const SubscriptionClass = this.subscriptionTypeAssociatedClasses[subscriptionType];
        if (!SubscriptionClass) {
            console.error(`❌ Subscription class not found for subscription ${subscription.id}.`);
            return null;
        }

        return shallowReactive(new SubscriptionClass({
            runtimeConfig,
            type: subscriptionType,
            status: subscription.status ?? parentSubscription?.status,
            item: parentSubscription ?? subscription,
            product,
            plan
        }));
    }

    /**
     * Modifies the subscription type based on user's parent status.
     *
     * @param {Sub.Type} subscriptionType - The initial subscription type of the user.
     * @returns {Sub.Type} The modified subscription type based on parent status.
     */
    private static checkUserParent (subscriptionType: Sub.Type): Sub.Type {
        if (subscriptionType === Sub.Type.Partner && __pbUser().hasParent) {
            subscriptionType = Sub.Type.ObjectStorage;
        }

        return subscriptionType;
    }

    /**
     * Creates multiple subscriptions.
     *
     * @param {StripeRaw.Subscription} subscription - The subscription information.
     * @param {RuntimeConfig["public"]} runtimeConfig - The runtime configuration object.
     * @returns {Promise<Subscription[]>} - A promise that resolves to an array of created subscriptions.
     */
    private static async createMultipleSubscriptions (subscription: StripeRaw.Subscription, runtimeConfig: RuntimeConfig["public"]): Promise<Subscription[]> {
        const subscriptions: Subscription[] = [];
        if (!subscription.items) {
            return subscriptions;
        }

        for (const item of subscription.items.data) {
            const sub = await this.createSubscription(item, subscription, runtimeConfig);
            if (sub) {
                sub.setStatus(subscription.status as Sub.Status);
                subscriptions.push(sub);
            }
        }

        return subscriptions;
    }

    /**
     * Retrieves subscription information based on the given subscription object.
     *
     * @param {StripeRaw.Subscription} subscription - The subscription object to retrieve information from.
     * @returns {Promise<SubscriptionInformations | null>} - A promise that resolves to a SubscriptionInformations object if successful, or null if an error occurs.
     */
    private static async getSubscriptionInformations (subscription: StripeRaw.Subscription): Promise<SubscriptionInformations | null> {
        const plan = this.getPlan(subscription);
        if (!plan) {
            console.error(`❌ Plan not found for subscription ${subscription.id}.`);
            return null;
        }

        const product = await this.getProduct(plan);
        if (!product) {
            console.error(`❌ Product not found for subscription ${subscription.id}.`);
            return null;
        }

        const metadata = getMetadata(product) ?? {};
        const subscriptionType = this.getSubscriptionType(metadata);
        if (!subscriptionType) {
            console.error(`❌ Subscription type not found for subscription ${subscription.id}.`);
            return null;
        }

        return {
            subscriptionType,
            product,
            plan
        };
    }

    /**
     * Retrieves the plan associated with the given subscription.
     *
     * @param subscription - The subscription object.
     * @returns {StripeRaw.Plan | null} - The plan associated with the subscription, or null if not found.
     */
    private static getPlan (subscription: StripeRaw.Subscription): StripeRaw.Plan | null {
        return subscription.items?.data[0].plan ?? subscription.plan ?? null;
    }

    /**
     * Retrieves a product based on the given plan.
     *
     * @param {StripeRaw.Plan} plan - The plan object.
     * @returns {Promise<StripeRaw.Product | null>} - The product associated with the plan, or null if not found.
     */
    private static async getProduct (plan: StripeRaw.Plan): Promise<StripeRaw.Product | null> {
        return await __product().getProduct(plan.product ?? "") ?? null;
    }

    /**
     * Retrieves the subscription type based on the provided metadata.
     *
     * @param {StripeRaw.Metadata} metadata - The metadata object.
     * @returns {Sub.Type | null} - The subscription type, or null if not found.
     */
    private static getSubscriptionType (metadata: StripeRaw.Metadata): Sub.Type | null {
        for (const key of SubscriptionConfig.keysToCheck) {
            const value: string | null = metadata[key] ?? null;
            if (value && SubscriptionValidator.isValidSubscriptionType(value)) {
                return SubscriptionValidator.getSuscriptionType(value);
            }
        }
        return null;
    }
}
