import type { Reactive } from "@vue/reactivity";
import type { RuntimeConfig } from "nuxt/schema";

import { __alert } from "~/stores/alert.store";
import { __subscription } from "~/stores/subscription.store";

import { getCustomerID } from "~/utils/customer";

import { routesStripe } from "~/routes/declarations";

import type { StripeRaw } from "~/types/stripe/raw";
import { Sub } from "~/types/subscriptions";

import SubscriptionFactory from "~/classes/factories/SubscriptionFactory";
import type Subscription from "~/classes/Subscription";
import { SubscriptionConfig } from "~/classes/SubscriptionConfig";
import { SubscriptionValidator } from "~/classes/SubscriptionValidator";
import { hasFetchSubscriptions } from "~/composables/notifications";
import Status = Sub.Status;

function waitForSubscriptions (): Promise<Reactive<Subscription>[]> {
    return new Promise(resolve => {
        const interval = setInterval(() => {
            if (__subscription().getIsFetched) {
                clearInterval(interval);
                resolve(__subscription().getSubscriptions);
            }
        }, 100);
    });
}

export async function getSubscriptionsByCustomerID (
    runtimeConfig: RuntimeConfig["public"],
    incomingCustomerId: string | null = null
): Promise<Reactive<Subscription>[]> {
    if (__subscription().getIsFetched) {
        return __subscription().getSubscriptions;
    }

    if (__subscription().getIsFetching) {
        return waitForSubscriptions();
    }

    __subscription().setFetching(true);
    __subscription().setFetched(false);

    log("🔍 Fetching subscriptions...");
    let customerID: string;
    try {
        customerID = await getCustomerID(incomingCustomerId);
    } catch (error) {
        console.error(error);
        __subscription().setFetched(true);
        __subscription().setFetching(false);
        return [];
    }

    log(`👤 Customer ID: %c${customerID}`, "font-weight: bold");
    let rawSubscriptions: Set<StripeRaw.Subscription>;
    try {
        rawSubscriptions = await fetchAllSubscriptions(customerID);
    } catch (error) {
        console.error("❌ Error fetching subscriptions:", error);
        return [];
    }

    const promises: Promise<Reactive<Subscription>[]>[] = [];

    for (const subscription of rawSubscriptions) {
        promises.push(SubscriptionFactory.create(subscription, runtimeConfig));
    }

    const results = await Promise.all(promises);
    const subscriptions: Reactive<Subscription>[] = results.flat();

    const subscriptionFiltered = filterSubscriptions(subscriptions);

    subscriptionFiltered.forEach(subscription => {
        // @ts-ignore
        __subscription().addSubscription(subscription);
    });

    if (subscriptionFiltered.length > 0 && !__subscription().hasOneOrMore(Status.Unpaid)) {
        __alert().remove("no-payment");
    }

    __subscription().setFetched(true);
    __subscription().setFetching(false);

    hasFetchSubscriptions.value = true;

    return subscriptionFiltered;
}

async function fetchAllSubscriptions (customerId: string): Promise<Set<StripeRaw.Subscription>> {
    const subscriptions = new Set<StripeRaw.Subscription>();

    try {
        await fetchSubscriptionsPage(subscriptions, customerId);
    } catch (error) {
        console.error("❌ Error fetching subscriptions:", error);
        return subscriptions;
    }

    if (!subscriptions.size) {
        console.error("❌ No subscriptions found.");
    }

    return subscriptions;
}

async function fetchSubscriptionsPage (
    subscriptions: Set<StripeRaw.Subscription>,
    customerId: string,
    startingAfter?: string
): Promise<void> {
    const params = startingAfter
        ? { customerId, starting_after: startingAfter }
        : { customerId };

    const stripeSubscriptions
        = await useFetchRoute<StripeRaw.Subscriptions>(routesStripe.getSubscriptions, params);

    stripeSubscriptions.data.forEach(subscription => subscriptions.add(subscription));

    if (stripeSubscriptions.has_more) {
        await fetchSubscriptionsPage(
            subscriptions,
            customerId,
            stripeSubscriptions.data[stripeSubscriptions.data.length - 1].id
        );
    }
}

export function getMetadata (product: StripeRaw.ObjectWithMetadata): StripeRaw.Metadata {
    return product.metadata;
}

function sortSubscriptions (subscriptions: Reactive<Subscription>[]): Reactive<Subscription>[] {
    return subscriptions.sort((a, b) => {
        let sortScore: number;
        // 1. Order by subscription type
        sortScore = a.getType().localeCompare(b.getType()) * 100;

        // 2. Sort by status respecting the priority in SubscriptionConfig.subscriptionsPriority
        sortScore += SubscriptionConfig.subscriptionsPriority.indexOf(a.getStatus())
            - SubscriptionConfig.subscriptionsPriority.indexOf(b.getStatus());

        if (sortScore === 0) {
            // 3. Sort by creation date
            sortScore = a.getCreated(true) > b.getCreated(true) ? 1 : -1;
        }

        return sortScore;
    });
}

function logFilteredSubscriptionWarn (
    subscription: Reactive<Subscription>,
    subscriptionsMap: Record<Sub.Type, Reactive<Subscription>[]>,
    type: Sub.Type,
    statusA: Sub.Status,
    statusB: Sub.Status
): void {
    const boldCss = "font-weight: bold";
    const normalCss = "font-weight: normal";
    const isSameStatus = statusA === statusB;
    const typeStatus = `💥 Subscription filtered: ${subscription.getType()} ${subscription.getStatus()} already exists.\nKeeping`;
    const keptValue = isSameStatus ? subscription.subscriptionItem.id : statusA;
    const discardedValue = isSameStatus ? subscriptionsMap[type][0].subscriptionItem.id : statusB;
    console.warn(
        `${typeStatus} "%c${keptValue}%c" instead of "%c${discardedValue}%c"`,
        boldCss,
        normalCss,
        boldCss,
        normalCss
    );
}

function filterSubscriptions (subscriptions: Reactive<Subscription>[]): Reactive<Subscription>[] {
    sortSubscriptions(subscriptions);

    const subscriptionMap: Record<Sub.Type, Reactive<Subscription>[]> = {
        [Sub.Type.Drive]: [],
        [Sub.Type.DrivePro]: [],
        [Sub.Type.Partner]: [],
        [Sub.Type.ObjectStorage]: []
    };

    subscriptions.forEach(subscription => {
        const type = subscription.getType();
        if (!subscriptionMap[type]) {
            subscriptionMap[type] = [];
        }

        if (SubscriptionValidator.canHaveMultipleSubscriptions(type)) {
            subscriptionMap[type].push(subscription);
            return;
        }

        if (!subscriptionMap[type].length) {
            subscriptionMap[type].push(subscription);
            return;
        }
        subscriptionMap[type].forEach(existingSubscription => {
            const hasPriority = SubscriptionValidator.isMostHighPriority(
                subscription.getStatus(),
                existingSubscription.getStatus()
            );

            if (hasPriority) {
                subscriptionMap[type] = [ subscription ];
                return;
            }

            logFilteredSubscriptionWarn(subscription, subscriptionMap, type, subscription.getStatus(), existingSubscription.getStatus());
        });
    });

    return Object.values(subscriptionMap).flat();
}
