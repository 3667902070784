import type { ToRefs } from "vue";

import { __pbUser } from "~/stores/pb-user.store";
import { __subscription } from "~/stores/subscription.store";

import type { PBCollection } from "~/types/pb/collections";

import Subscription, { type SubscriptionConfig } from "~/classes/Subscription";

/**
 * Represents the subscription information for an object storage.
 *
 * @interface ISubscriptionObjectStorage
 * @property {boolean} isActive - The status of the subscription
 * @property {boolean} isDisabled - The status of the subscription
 * @property {boolean} isLoading - The status of the subscription
 * @property {number} bucketsAmount - The amount of buckets
 * @property {string} bucketsRoute - The route to the buckets
 * @property {number} identifiersAmount - The amount of identifiers
 * @property {string} identifiersRoute - The route to the identifiers
 * @property {number} objectsAmount - The amount of objects
 * @property {number} spaceDisposed - The disposed space
 * @property {number} spaceTotal - The total space
 * @property {number} spacePercent - The percentage of space used
 */
export interface ISubscriptionObjectStorage {
    bucketsAmount: number,
    bucketsRoute: string,
    identifiersAmount: number,
    identifiersRoute: string,
    objectsAmount: number,
    spaceDisposed: number,
    spaceTotal: number,
    spacePercent: number,
    isUnlimited: boolean,
    isQuotaReached: boolean;
}

export type SubObjectStorage = Subscription & ISubscriptionObjectStorage & {
    setSpaceTotal: (space: number) => void,
    incrementObjectsAmount: (amount: number) => void,
    incrementSizeDisposed: (size: number) => void,
    reset: () => void,
    fetch: () => Promise<void>,
};

/**
 * Represents a subscription object storage.
 */
export default class SubscriptionObjectStorage extends Subscription implements ToRefs<ISubscriptionObjectStorage> {
    public bucketsAmount = ref<number>(0);

    public bucketsRoute = ref<string>("");

    public identifiersAmount = ref<number>(0);

    public identifiersRoute = ref<string>("");

    public objectsAmount = ref<number>(0);

    public spaceDisposed = ref<number>(0);

    public spaceTotal = ref<number>(0);

    public spacePercent = ref<number>(0);

    public isUnlimited = ref<boolean>(false);

    public isQuotaReached = computed(() => this.spaceDisposed.value >= this.spaceTotal.value);

    /**
     *  Creates a new SubscriptionObjectStorage instance.
     *
     * @param {SubscriptionConfig} config - The configuration object for the subscription object storage
     */
    public constructor (config: SubscriptionConfig) {
        super(config);
    }

    /**
     * Set space total.
     *
     * @param {number} space - The space total
     */
    public setSpaceTotal (space: number): void {
        this.spaceTotal.value = space;
        this.isUnlimited.value = space === -1;
    }

    /**
     * Increment objects amount.
     *
     * @param {number} amount - The amount to increment
     * @returns {void}
     */
    public incrementObjectsAmount (amount: number): void {
        this.objectsAmount.value += amount;
    }

    /**
     * Increment size disposed.
     *
     * @param {number} size - The size to increment
     * @returns {void}
     */
    public incrementSizeDisposed (size: number): void {
        this.spaceDisposed.value += size;
    }

    /**
     * Reset the objects amount and size disposed.
     */
    public reset (): void {
        this.objectsAmount.value = 0;
        this.spaceDisposed.value = 0;
    }

    /**
     * Fetch object storage data.
     *
     * @returns {Promise<void>}
     */
    public async fetch (): Promise<void> {
        __subscription().setLoading(this.type, true);
        await waitForPB();
        const teamId = __pbUser().getTeamId;
        if (!teamId) {
            console.warn("No team ID found");
            __subscription().setLoading(this.type, false);
            return;
        }

        try {
            const aboS3 = await PB.i
                .collection("abo_s3")
                .getFirstListItem<PBCollection.AboS3>(`equipe="${teamId}"`, { requestKey: `abo_s3-${teamId}` });
            if (aboS3) {
                this.setSpaceTotal(aboS3.quota);
            }
        } catch {
            console.warn(
                `Can't fetch "abo_s3" for team : %c${teamId}`,
                "font-weight: bold"
            );
        } finally {
            __subscription().setLoading(this.type, false);
        }
    }
}
