export namespace Sub {
    export enum Type {
        ObjectStorage = "object-storage",
        Partner = "partner",
        Drive = "drive",
        DrivePro = "drive-pro"
    }

    export enum Status {
        Incomplete = "incomplete",
        IncompleteExpired = "incomplete_expired",
        Trialing = "trialing",
        Active = "active",
        PastDue = "past_due",
        Unpaid = "unpaid",
        Canceled = "canceled",

        None = "none"
    }

    export enum Periodicity {
        Monthly = "monthly",
        Yearly = "yearly"
    }
}
