import { defineStore } from "pinia";

import { __identifier } from "~/stores/identifier.store";
import { __subscription } from "~/stores/subscription.store";

import type { Alert } from "~/types/alert";
import type { PBCollection } from "~/types/pb/collections";
import { PBEnums } from "~/types/pb/enums";
import { Sub } from "~/types/subscriptions";

import { SubscriptionValidator } from "~/classes/SubscriptionValidator";
import { dateApplicable } from "~/composables/date";

export const __alert = defineStore(
    "alertStore",
    {
        state: (): {
            isChecked: boolean
            alerts: Alert[]
            fetchedNotifications: Record<string, PBCollection.Notifications>
            closedAlerts: Alert["id"][],
            translations: Record<string, Record<string, string>>
        } => ({
            isChecked: false,
            alerts: [],
            fetchedNotifications: {},
            closedAlerts: [],
            translations: {}
        }),
        getters: {
            getAlerts: state => state.alerts.filter((alert: Alert) => !state.closedAlerts.includes(alert.id)),
            getClosedAlerts: (): Alert["id"][] => {
                const closedAlerts = localStorage.getItem("leviia-closed-alerts");

                if (closedAlerts) {
                    return JSON.parse(closedAlerts);
                }

                return [];
            },
            hasAlert: state => (id: string): boolean => state.alerts.some((alert: Alert) => alert.id === id),
            getTranslation: state => (id: string, key: string, locale: string): string => {
                const translationKey = `${key}_${id}`;
                if (state.translations[translationKey]) {
                    return state.translations[translationKey][locale]
                        ?? state.translations[translationKey]["fr"];
                }

                return "";
            }
        },
        actions: {
            /**
             * Adds a new alert to the list of alerts.
             *
             * @param {Alert} alert - The alert to be added.
             * @return {void}
             */
            add (alert: Alert): void {
                if (this.alerts.find((a: { id: string }) => a.id === alert.id)) {
                    return;
                }

                // if is not closed
                if (this.closedAlerts.includes(alert.id)) {
                    return;
                }

                alert.isDismissible = alert.isDismissible ?? true;
                this.alerts.push(alert);
            },
            /**
             * Removes an alert from the "alerts" array based on its ID.
             *
             * @param {string} id - The ID of the alert to be removed.
             * @param {boolean} [force=false] - Optional parameter to indicate whether to forcefully remove the alert even if it is not dismissible.
             * @return {void}
             */
            remove (id: string, force: boolean = false): void {
                this.alerts = this.alerts.filter((alert: Alert) => {
                    if (alert.id === id) {
                        return force || alert.isDismissible;
                    }

                    return true;
                });

                this.closedAlerts.push(id);
            },
            close (id: string): void {
                const alert = this.alerts.find((a: { id: string }) => a.id === id);
                if (!alert) {
                    return;
                }

                const closedAlerts = this.getClosedAlerts;
                closedAlerts.push(id);

                if (alert.isFromServer) {
                    localStorage.setItem("leviia-closed-alerts", JSON.stringify(closedAlerts));
                }

                this.getClosedAlerts.forEach((closedAlert: Alert["id"]): void => {
                    this.alerts = this.alerts.filter((alert: Alert) => alert.id !== closedAlert);
                });
                this.remove(id, true);
            },
            /**
             * Fetches notifications from the server.
             *
             * @returns A Promise that resolves once the notifications are fetched.
             */
            async fetchNotifications (): Promise<void> {
                if (!__pbUser().initialized) {
                    await __pbUser().init();
                    await waitForPB();
                }

                const notifications = await PB.i.collection("notifications")
                    .getFullList<PBCollection.Notifications>();

                notifications.forEach((notification: PBCollection.Notifications) => {
                    if (!this.fetchedNotifications[notification.id]) {
                        this.fetchedNotifications = { ...this.fetchedNotifications, [notification.id]: notification };
                    }
                });
            },
            /**
             * Checks the fetched notifications and adds or removes them based on their applicability.
             *
             * @returns {void}
             */
            checkNotifs (force: boolean = false): void {
                if (!force && this.isChecked) {
                    return;
                }

                Object.values(this.fetchedNotifications)
                    .forEach(notification => {
                        this.isChecked = true;
                        if (this.isApplicable(notification)) {
                            this.addTranslations(notification.id, notification);

                            this.add({
                                id: notification.id,
                                isDismissible: notification.fermable,
                                type: notification.style,
                                isFromServer: true
                            });
                        } else {
                            this.remove(notification.id);
                        }
                    });
            },
            /**
             * Watches the subscription and adds or removes notifications based on the subscription status.
             *
             * @return {void}
             */
            watchSubscription (): void {
                watch(__subscription, () => this.checkNotifs(true), { immediate: true });
            },
            /**
             * Checks if a notification is applicable based on its start date, end date, abonnements, and clusters.
             *
             * @param {PBCollection.Notifications} notification - The notification object.
             * @returns {boolean} - Whether the notification is applicable or not.
             */
            isApplicable (notification: PBCollection.Notifications): boolean {
                const dateA = dateApplicable(notification.start_date, notification.end_date);
                const subA = this.subscriptionApplicable(notification.abonnements);
                const clusterA = this.clustersApplicable(notification.clusters, notification.abonnements);

                return dateA && subA && clusterA;
            },
            /**
             * Checks if the given subscriptions are applicable for the current user.
             *
             * @param {PBCollection.Notifications['abonnement']} abonnements - The subscriptions to check for applicability.
             * @returns {boolean} - True if the subscriptions are applicable, otherwise false.
             */
            subscriptionApplicable (abonnements: PBCollection.Notifications["abonnements"]): boolean {
                if (abonnements.includes("all")) {
                    return true;
                }

                const objectStorage = __subscription().getSubscriptionObjectStorage();
                const drive = __subscription().getSubscriptionDrive();
                const drivePro = __subscription().getSubscriptionDrivePro();
                const partner = __subscription().getSubscriptionPartner();

                const subs = abonnements
                    .map((abo: string) => SubscriptionValidator.getSuscriptionType(abo))
                    .filter(abo => abo !== null);

                if (objectStorage && subs.includes(Sub.Type.ObjectStorage)) {
                    return true;
                }

                if (partner && subs.includes(Sub.Type.Partner)) {
                    return true;
                }

                if (drive && subs.includes(Sub.Type.Drive)) {
                    return true;
                }

                return (drivePro && subs.includes(Sub.Type.DrivePro)) || false;
            },
            /**
             * Determines if the given clusters are applicable for the given subscriptions.
             *
             * @param {PBCollection.Notifications['clusters']} clusters - The clusters to check.
             * @param {PBCollection.Notifications['abonnements']} subscriptions - The subscriptions to check against.
             * @returns {boolean} - Returns true if the clusters are applicable for the subscriptions, otherwise false.
             */
            clustersApplicable (clusters: PBCollection.Notifications["clusters"], subscriptions: PBCollection.Notifications["abonnements"]): boolean {
                if (clusters.includes("all")) {
                    return true;
                }
                let canAccess = false;

                if (subscriptions.includes(PBEnums.SubscriptionType.ObjectStorage)) {
                    if (__identifier().hasClusters(clusters)) {
                        canAccess = true;
                    }
                }

                return canAccess;
            },
            addTranslations (id: string, values: any) {
                const translationKeys: [
                    "title",
                    "message"
                ] = [ "title", "message" ];

                translationKeys.forEach(translationKey => {
                    const translations = {
                        "fr": values[`${translationKey}_fr`],
                        "en": values[`${translationKey}_en`] ?? values[`${translationKey}_fr`]
                    };

                    Object.entries(translations).forEach(([ language, value ]) => {
                        this.addTranslation(`${translationKey}_${id}`, language, value);
                    });
                });
            },
            addTranslation (key: string, lang: string, value: string): void {
                if (!this.translations[key]) {
                    this.translations[key] = {};
                }

                this.translations[key][lang] = value;
            }
        }
    }
);
