import { defineStore } from "pinia";

import { __clients } from "~/stores/clients.store";
import { __stripe } from "~/stores/stripe.store";
import { __user } from "~/stores/user.store";

import { useFetchRoute } from "~/utils/fetch";

import { routesKC, routesSIB } from "~/routes/declarations";

import type { PBCollection } from "~/types/pb/collections";
import { PBEnums } from "~/types/pb/enums";
import type { CreateTeam, CreateTeamUser } from "~/types/pb/team";
import type { PBTeamMember, PBTeamMembers } from "~/types/pocketbase";
import { Sub } from "~/types/subscriptions";

import { PB } from "~/composables/db";
import { getRoleFromName, getRoleScore } from "~/composables/role";

export const __pbUser = defineStore("pbUserStore", {
    state: () => <{
        notInPB: boolean
        initialized: boolean
        loading: boolean
        client: PBCollection.Clients | null
        team: PBCollection.Equipes | null
        abo_s3: string | null
        role: PBEnums.Roles
        quotaAllowed: number
        teamMembers: PBTeamMembers
    }>({
        notInPB: false,
        initialized: false,
        loading: false,
        client: null,
        team: null,
        role: PBEnums.Roles.Invoice,
        quotaAllowed: 0,
        teamMembers: {},
        abo_s3: null
    }),
    getters: {
        isInitialized: state => state.initialized,
        isLoading: state => state.loading,

        getClient: state => state.client,
        getTeam: state => state.team,
        getRole: state => state.role,

        getUsername: state => state.client?.username,
        getEmail: state => state.client?.email,
        isDirect: state => !state.team?.parent,
        getCustomerId: state => state.team?.customer_id,
        getParentId: state => state.team?.parent,
        getReference: state => state.team?.reference,
        getCreatedDate: state => state.team?.created,

        getClientId: state => state.client?.id,
        getTeamId: state => state.team?.id,
        getTeamUpdatedDate: state => state.team?.updated,

        hasParent: state => !!state.team?.parent,
        isReseller: state => !!state.team?.parent && __subscription().has(Sub.Type.ObjectStorage),
        isDeactivated: state => (state.team?.parent_deactivated ?? state.team?.leviia_deactivated) ?? false,

        getTeamMembers (): PBTeamMember[] {
            if (!this.team || !this.client) {
                return [];
            }

            return Object.values(this.teamMembers)
                .sort(sortMembers);
        }
    },
    actions: {
        /**
         * Checks if the user has the specified role.
         *
         * @param {PBEnums.Roles} role - The role to be checked.
         * @return {boolean} - Returns true if the user has the specified role, otherwise returns false.
         */
        hasRole (role: PBEnums.Roles): boolean {
            return this.role === role;
        },
        /**
         * Checks whether the current user is an owner.
         *
         * @returns {boolean} True if the current user is an owner, false otherwise.
         */
        isOwner: function (): boolean {
            return this.hasRole(PBEnums.Roles.Owner);
        },
        /**
         * Checks if the current user is a super admin.
         *
         * @return {boolean} Returns a boolean value indicating whether the current user is a super admin.
         */
        isSuperAdmin (): boolean {
            return this.hasRole(PBEnums.Roles.Admin);
        },
        /**
         * Checks if the user has the role of Invoice.
         *
         * @return {boolean} - Returns true if the user has the role of Invoice, false otherwise.
         */
        isInvoice (): boolean {
            return this.hasRole(PBEnums.Roles.Invoice);
        },

        /**
         * Checks if the current role is greater than the given role.
         *
         * @param {PBEnums.Roles} role - The role to compare against.
         * @returns {boolean} - Whether the current role is greater than the given role.
         */
        isRoleGreaterThan (role: PBEnums.Roles | keyof typeof PBEnums.Roles): boolean {
            role = getRoleFromName(role);

            return getRoleScore(this.role) < getRoleScore(role);
        },
        /**
         * Loads user information asynchronously.
         *
         * @param {string} username - The username of the user to load.
         * @returns {Promise<void>} - A promise that resolves when the user information is loaded.
         */
        loadUser: async function (username: string): Promise<void> {
            const client = await this.initClient(username);
            if (!client) {
                this.loading = false;
                this.notInPB = true;
                return;
            }

            const [ team, role ] = await this.initTeamAndRoles(client.id);
            if (!team || !role) {
                this.loading = false;
                return;
            }

            this.fetchTeamMembers();

            if ("customer_id" in team && team.customer_id) {
                __stripe().setCustomerId(team.customer_id);
            }

            if (this.hasParent) {
                await this.defineParentSubscriptions(team.parent);
            }

            const unwatchPartner = watch(() => __subscription().getSubscriptionPartner(), async () => {
                if (__subscription().getSubscriptionPartner()) {
                    await __clients().fetchClients(team.id);
                    await this.fetchPartnerSubscriptionInfos(team.id);
                    unwatchPartner();
                }

                setTimeout(() => {
                    unwatchPartner();
                }, 30000);
            });

            const unwatchS3 = watch(() => __subscription().has(Sub.Type.ObjectStorage), async () => {
                if (__subscription().has(Sub.Type.ObjectStorage)) {
                    await this.fetchS3SubscriptionInfos(team.id);
                    unwatchS3();
                }

                setTimeout(() => {
                    unwatchS3();
                }, 30000);
            });

            this.loading = false;
            this.initialized = true;
        },

        /**
         * Initializes the system.
         *
         * @param {string | null} [username=null] - The username of the user. If not provided, it will try to use the current logged in user's username.
         * @returns {Promise<void>} - A promise that resolves when the initialization is complete.
         */
        async init (username: string | null = null): Promise<void> {
            if (this.initialized || this.loading || this.notInPB) {
                if (this.loading) {
                    await new Promise(resolve => setTimeout(resolve, 2000));
                }
                return;
            }

            this.loading = true;

            if (!username) {
                await waitForKC();

                username = __user().getUser?.username;

                if (!username) {
                    this.loading = false;
                    return Promise.reject(new Error("Username is not defined"));
                }
            }

            try {
                await PB.auth();
            } catch (e) {
                return Promise.reject(e);
            }

            await this.loadUser(username);
        },
        /**
         * Sets up the subscriptions for a parent entity.
         *
         * @param {string | undefined} parentId - The ID of the parent entity.
         * @return {Promise<void>} - A promise that resolves with nothing.
         */
        async defineParentSubscriptions (parentId: string | undefined): Promise<void> {
            if (!parentId) {
                return;
            }

            const parentTeam = await __clients().getTeam(parentId);
            if (!parentTeam) {
                return;
            }

            if (!parentTeam.customer_id) {
                console.warn("Parent team has no customer ID");
                return;
            }

            __stripe().setParentCustomerId(parentTeam.customer_id);
        },
        /**
         * Initializes the client by retrieving the user with the given username.
         *
         * @param {string} username - The username of the client to be retrieved.
         * @return {Promise<PBCollection.Clients>} - A promise that resolves with the retrieved client.
         * @throws {string} - If the client cannot be found, a rejection with error message 'PB | Client not found' will be thrown.
         */
        async initClient (username: string): Promise<PBCollection.Clients | undefined> {
            const client = await this.getUserWithUsername(username);
            if (!client) {
                return undefined;
            }
            this.client = client;

            return client;
        },
        /**
         * Initialize the team and roles for a client
         * @param {string} clientId - The client ID
         * @return {Promise<[PBCollection.Equipes, PBEnums.Roles]>} A promise that resolves to an array containing the team and roles
         * @throws {string} If clientId is not defined, or if team or role are not found
         */
        async initTeamAndRoles (clientId: string): Promise<[
                PBCollection.Equipes | undefined,
                PBEnums.Roles | undefined
        ]> {
            if (!clientId) {
                console.warn("Client ID is not defined");
                return [ undefined, undefined ];
            }
            const [ team, role ] = await this.getTeamWithClient(clientId);
            if (!team) {
                console.warn("Team not found");
                return [ undefined, undefined ];
            } else if (!role) {
                console.warn("Role not found");
                return [ undefined, undefined ];
            }
            this.team = team;
            this.role = role;

            return [ team, role ];
        },
        /**
         * Retrieves a client user with the given username.
         *
         * @param {string} username - The username of the client user to retrieve.
         * @returns {Promise<PBCollection.Clients | undefined>} A promise that resolves to the client user object with the given username, or undefined if not found.
         */
        async getUserWithUsername (username: string): Promise<PBCollection.Clients | undefined> {
            try {
                return await PB.i.collection("clients")
                    .getFirstListItem<PBCollection.Clients>(`username="${username}"`);
            } catch (_e) {
                return undefined;
            }
        },
        async getUserWithId (id: string): Promise<PBCollection.Clients | undefined> {
            return await PB.i.collection("clients")
                .getFirstListItem<PBCollection.Clients>(`id="${id}"`);
        },
        /**
         * Retrieves the team and role associated with a given client.
         *
         * @param {string} clientId - The unique identifier of the client.
         * @return {Promise<[PBCollection.Equipes | undefined, PBEnums.Roles | undefined]>} - A promise that resolves to an array containing the team and role associated with the client. If no team or role is found, undefined is returned for both.
         */
        async getTeamWithClient (clientId: string): Promise<[
                PBCollection.Equipes | undefined,
                PBEnums.Roles | undefined
        ]> {
            const clientTeam = await PB.i.collection("equipes_clients")
                .getFullList<PBCollection.EquipesClients & {
                    expand: {
                        equipe: PBCollection.Equipes
                    }
                }>({
                    expand: "equipe",
                    filter: `client="${clientId}"`,
                    requestKey: `equipes_clients-${clientId}`
                });

            if (!clientTeam || clientTeam.length === 0) {
                return [ undefined, undefined ];
            }

            let equipe: PBCollection.Equipes | undefined;
            let role: PBEnums.Roles | undefined;
            clientTeam.forEach((team: { expand: { equipe: PBCollection.Equipes }, role: PBEnums.Roles }) => {
                equipe = team.expand.equipe ?? undefined;
                role = team.role ?? undefined;
            });

            return [ equipe, role ];
        },
        /**
         * Retrieves a member from a team based on their role.
         *
         * @param {string} teamId - The ID of the team.
         * @param {PBEnums.Roles} role - The role of the team member.
         * @returns {Promise<PBCollection.Clients | undefined>} - A promise that resolves to the client representing the team member, or undefined if not found.
         */
        async getMemberFromTeamRole (teamId: string, role: PBEnums.Roles): Promise<PBCollection.Clients | undefined> {
            const teamMember = await PB.i.collection("equipes_clients")
                .getFirstListItem<PBCollection.EquipesClients & {
                    expand: {
                        client: PBCollection.Clients
                    }
                }>(`equipe.id="${teamId}" && role="${role}"`, {
                    requestKey: `equipes_clients-${teamId}`
                });
            if (!teamMember) {
                return undefined;
            }
            const client = await this.getUserWithId(teamMember.client);
            return client ?? undefined;
        },
        /**
         * Retrieves partner subscription information based on the provided team ID.
         *
         * @param {string} teamId - The unique identifier of the team.
         * @return {Promise<void>} - Resolves after the partner subscription information has been fetched and updated successfully.
         */
        async fetchPartnerSubscriptionInfos (teamId: string): Promise<void> {
            if (!teamId) {
                console.warn("Team ID is not defined", teamId);
                return;
            }

            const aboDistributeur = await PB.i.collection("abo_distributeur")
                .getFirstListItem<PBCollection.AboDistributeur>(`equipe="${teamId}"`);

            if (!aboDistributeur) {
                return;
            }

            const partner = __subscription().getSubscriptionPartner();
            if (partner) {
                partner.setSpaceMaxAllowed(aboDistributeur.quota_s3 ?? 0);
            }
        },
        /**
         * Retrieves the owner of a team.
         *
         * @param {string} teamId - The ID of the team.
         * @returns {Promise<PBCollection.Clients | undefined>} - A Promise that resolves to the owner of the team, if found. Otherwise, it resolves to undefined.
         */
        async getTeamOwner (teamId: string): Promise<PBCollection.Clients | undefined> {
            if (!teamId) {
                console.warn("Team ID is not defined", teamId);
                return;
            }

            return await this.getMemberFromTeamRole(teamId, PBEnums.Roles.Owner);
        },
        /**
         * Retrieves S3 subscription information based on the provided team ID.
         *
         * @param {string} teamId - The unique identifier of the team.
         * @return {Promise<void>} - Resolves after the S3 subscription information has been fetched and updated successfully.
         */
        async fetchS3SubscriptionInfos (teamId: string): Promise<void> {
            if (!teamId) {
                console.warn("Team ID is not defined", teamId);
                return;
            }

            const aboS3 = await PB.i.collection("abo_s3")
                .getFirstListItem<PBCollection.AboS3>(`equipe="${teamId}"`);

            if (!aboS3) {
                return;
            }

            this.abo_s3 = aboS3.id;

            const objectStorage = __subscription().getSubscriptionObjectStorage();
            if (objectStorage) {
                objectStorage.setSpaceTotal(aboS3.quota ?? 0);
            }
        },
        async getAboS3ID (teamId?: string | undefined): Promise<string | undefined> {
            if (this.abo_s3) {
                return this.abo_s3;
            }

            if (!teamId) {
                teamId = this.team?.id;
            }
            if (!teamId) {
                return undefined;
            }

            const aboS3 = await PB.i.collection("abo_s3")
                .getFirstListItem<PBCollection.AboS3>(`equipe="${teamId}"`);

            if (!aboS3) {
                return undefined;
            }

            this.abo_s3 = aboS3.id;

            return aboS3.id;
        },
        // getParent
        async getParent (): Promise<PBCollection.Equipes | undefined> {
            if (!this.team?.parent) {
                return undefined;
            }

            const parentTeam = await __clients().getTeam(this.team.parent);
            if (!parentTeam) {
                return undefined;
            }

            return parentTeam;
        },
        /**
         * Saves team members by taking an array of `equipesClients` objects and adding them to the `teamMembers` object.
         *
         * @param {Array<PBCollection.EquipesClients & { expand: { client: PBCollection.Clients } }>} equipesClients - The array of `equipesClients` objects to save as team members.
         */
        saveTeamMembers (equipesClients: Array<PBCollection.EquipesClients & {
            expand: { client: PBCollection.Clients }
        }>) {
            equipesClients.forEach(equipeClient => {
                if (!hasAuthorization(RoleRules.Partner)) {
                    return;
                }

                if (!equipeClient || !equipeClient.expand || !equipeClient.expand.client) {
                    console.warn("PB: Equipe client or client is not defined", equipeClient);
                    return;
                }

                const client = equipeClient.expand.client;
                const role = equipeClient.role;
                const created = equipeClient.created;
                check2FA(client.username).then(twoFactorEnabled => {
                    if (!client || !role) {
                        console.warn("Client or role is not defined", client, role);
                        return;
                    }

                    this.teamMembers[client.id] = {
                        ...client,
                        role,
                        created,
                        twoFactorEnabled
                    };
                });
            });
        },
        /**
         * Fetches the team members of a specified team.
         *
         * @returns {void} This method does not return anything.
         */
        fetchTeamMembers (): void {
            PB.i.collection("equipes_clients")
                .getFullList<PBCollection.EquipesClients & {
                    expand: {
                        client: PBCollection.Clients
                    }
                }>({
                    filter: `equipe="${this.getTeamId}"`,
                    requestKey: `equipes_clients-${this.getTeamId}`,
                    expand: "client"
                })
                .then(equipesClients => {
                    this.saveTeamMembers(equipesClients);
                });
        },
        /**
         * Retrieves a page of team members based on the specified page number and pagination value.
         *
         * @param {number} page - The page number to retrieve.
         * @param {number} pagination - The number of team members per page.
         * @returns {PBTeamMember[]} - An array of team members for the requested page.
         */
        getTeamMembersByPage (page: number, pagination: number): PBTeamMember[] {
            const allTeamMembers = this.getTeamMembers;
            const start = (page - 1) * pagination;
            return allTeamMembers.slice(start, start + pagination);
        },
        async createTeam (user: CreateTeamUser) {
            try {
                const parentTeam = this.getTeam;
                const parentUsername = this.getUsername;
                const parentEmail = this.getEmail;
                if (!parentUsername || !parentEmail) {
                    console.warn("Username or email is not defined");
                    // noinspection ExceptionCaughtLocallyJS
                    throw new Error("Problème lors de la récupération de votre compte");
                }

                if (!parentTeam) {
                    console.warn("Team is not defined");
                    // noinspection ExceptionCaughtLocallyJS
                    throw new Error("Problème lors de la récupération de votre compte");
                }

                const body: CreateTeam = {
                    username: parentUsername,
                    email: parentEmail,
                    team: {
                        name: parentTeam.reference,
                        id: parentTeam.id
                    },
                    user
                };

                await useFetchRoute(routesSIB.sendPBEquipeCreated, null, body);

                __alert().add({
                    id: "team-created",
                    type: "success",
                    title: "notification.team_created.success.title",
                    message: "notification.team_created.success.content",
                    isDismissible: true
                });
            } catch (_e) {
                __alert().add({
                    id: "team-created",
                    type: "danger",
                    title: "notification.team_created.error.title",
                    message: "notification.team_created.error.content",
                    isDismissible: true
                });
            }
        },
        getTeamMemberById (id: string): PBTeamMember | undefined {
            return this.teamMembers[id] ?? undefined;
        },
        async getKcUserById (id: string): Promise<unknown> {
            const user = this.teamMembers[id] ?? undefined;
            if (!user) {
                return undefined;
            }

            const kcUser = await useFetchRoute(routesKC.getUser, { username: user.username });
            if (!kcUser) {
                return undefined;
            }

            return kcUser;
        },
        async updateMember (id: string, role: PBEnums.Roles): Promise<void> {
            try {
                const member = this.teamMembers[id];
                if (!member) {
                    return;
                }

                const equipesClient = await PB.i.collection("equipes_clients").getFirstListItem<PBCollection.EquipesClients>(`client="${member.id}"`);

                await PB.i.collection("equipes_clients").update(equipesClient.id, { role });

                __alert().add({
                    id: "member-updated",
                    type: "success",
                    title: "Utilisateur mis à jour",
                    message: "L'utilisateur a bien été mis à jour.",
                    isDismissible: true
                });

                this.teamMembers[id].role = role;
            } catch (_e) {
                __alert().add({
                    id: "member-updated",
                    type: "danger",
                    title: "Erreur",
                    message: "Une erreur est survenue lors de la mise à jour du membre.",
                    isDismissible: true
                });
            }
        },
        async deleteTeamMember (id: string): Promise<void> {
            try {
                const member = this.teamMembers[id];
                if (!member) {
                    return;
                }

                const equipesClient = await PB.i.collection("equipes_clients")
                    .getFirstListItem<PBCollection.EquipesClients>(`client="${member.id}"`);

                await PB.i.collection("equipes_clients").delete(equipesClient.id);

                __alert().add({
                    id: "member-deleted",
                    type: "success",
                    title: "Utilisateur supprimé",
                    message: "L'utilisateur a bien été supprimé.",
                    isDismissible: true
                });

                delete this.teamMembers[id];
            } catch (_e) {
                __alert().add({
                    id: "member-deleted",
                    type: "danger",
                    title: "Erreur",
                    message: "Une erreur est survenue lors de la suppression du membre.",
                    isDismissible: true
                });
            }
        },
        async getAboS3 (): Promise<string | null> {
            if (this.abo_s3) {
                return this.abo_s3;
            }

            const aboS3 = await PB.i.collection("abo_s3")
                .getFirstListItem<PBCollection.AboS3>(`equipe="${this.getTeamId}"`);

            if (!aboS3) {
                return null;
            }

            this.abo_s3 = aboS3.id;

            return aboS3.id;
        }
    }
});
