import { defineStore } from "pinia";

import { __stripe } from "~/stores/stripe.store";

import type { StripeRaw } from "~/types/stripe/raw";

interface productInterface {
    products: Map<string, StripeRaw.Product>;
}

export const __product = defineStore(
    "productStore",
    {
        state: (): productInterface => ({
            products: new Map()
        }),
        getters: {},
        actions: {
            getLocalProduct (productID: string): StripeRaw.Product | null {
                return this.products.get(productID) || null;
            },
            async getProduct (productID: string): Promise<StripeRaw.Product | null> {
                const product = this.getLocalProduct(productID);
                if (product) {
                    return product;
                }

                return await this.fetchProduct(productID) || null;
            },
            async fetchProduct (productID: string): Promise<StripeRaw.Product | null> {
                const product = await __stripe().getProduct(productID);
                this.products.set(productID, product);

                return product || null;
            }
        }
    }
);
